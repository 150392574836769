




















































import { useInvoiceAR } from "@/hooks";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { InvoicePostingDataView } from "@/models/interface/account-receivable";
import { ApproveInvoice } from "@/models/interface/invoice.interface";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "InvoicePostingModal",
  props: {
    value: {
      default: false,
      required: true,
      type: Boolean,
    },
    invoices: {
      required: false,
      type: Array as () => InvoicePostingDataView[],
      default: [],
    },
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      formState: {
        receivedDate: null as Moment | null,
        pic: "",
      },
      loading: {
        approve: false,
      },
      minimumDate: moment() as Moment,
      schemaValidation: {
        receivedDate: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        pic: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
      },
    };
  },
  watch: {
    invoices: {
      handler: "setMinDate",
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("input", false);
    },
    disabledDate(curr: Moment) {
      return curr > moment() || curr < this.minimumDate;
    },
    sortInvoices(values: InvoicePostingDataView[]): InvoicePostingDataView[] {
      const copy = [...values];
      copy.sort((a, b) => a.invoiceDate.valueOf() - b.invoiceDate.valueOf());
      return copy;
    },
    setMinDate() {
      const [oldest] = this.sortInvoices(this.invoices);
      this.minimumDate = oldest?.invoiceDate ?? moment();
    },
    async handleApprove() {
      const form = this.$refs.form as FormModel | null;
      if (!form) return;
      const { approveInvoice } = useInvoiceAR();
      try {
        await form.validate();

        const payload: ApproveInvoice = {
          invoiceReceivedDate: this.formState.receivedDate?.format() ?? "",
          picCustomerName: this.formState.pic,
        };
        const invoiceIds: string[] = this.invoices.map(item => item.invoiceId);
        this.loading.approve = true;
        await approveInvoice([payload], invoiceIds);
        this.emitsApprove();
      } finally {
        this.loading.approve = false;
      }
    },
    emitsApprove() {
      this.$emit("approved", true);
      this.handleClose();
    },
  },
});
