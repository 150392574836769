











































































































































import { generateUUID } from "@/helpers/uuid";
import { useBlob, useInvoiceAR } from "@/hooks";
import {
  InvoicePrintReceiptDocumentState,
  InvoicePrintReceiptState,
} from "@/models/interface/account-receivable";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { GenerateReceipt } from "@/models/interface/invoice.interface";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import printJS from "print-js";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "InvoicePrintReceiptModal",
  props: {
    value: {
      default: false,
      required: true,
      type: Boolean,
    },
    invoiceIds: {
      required: false,
      default: [],
      type: Array as () => string[],
    },
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  data() {
    return {
      loading: {
        print: false,
        data: false,
      },
      state: {
        customer: "",
        billingAddress: "",
        branch: "",
        documents: [],
      } as InvoicePrintReceiptState,
      selectedRowKeys: [] as string[],
      columns: [
        {
          title: this.$t("lbl_number_short"),
          dataIndex: "no",
        },
        {
          title: this.$t("lbl_document"),
          key: "document",
          scopedSlots: { customRender: "document" },
        },
        {
          title: this.$t("lbl_quantity"),
          key: "quantity",
          scopedSlots: { customRender: "quantity" },
        },
        {
          title: this.$t("lbl_uom"),
          key: "uom",
          scopedSlots: { customRender: "uom" },
        },
        {
          title: this.$t("lbl_note"),
          key: "note",
          scopedSlots: { customRender: "note" },
        },
      ],
    };
  },
  watch: {
    invoiceIds: {
      handler(values: string[]) {
        if (values.length > 0) {
          this.fetchGenerateReceipt(values);
        } else {
          this.state.billingAddress = "";
          this.state.branch = "";
          this.state.customer = "";
          this.state.documents = [];
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    handleClose() {
      this.$emit("input", false);
    },
    async fetchGenerateReceipt(ids: string[]) {
      const { generateReceipt } = useInvoiceAR();
      try {
        this.loading.data = true;
        const response = await generateReceipt(ids);
        this.state = this.toState(response);
      } catch {
        this.handleClose();
      } finally {
        this.loading.data = false;
      }
    },
    toState(data: GenerateReceipt): InvoicePrintReceiptState {
      return {
        customer: data.customerName,
        billingAddress: data.billToAddress,
        branch: data.branchName,
        documents: data.details.map((item, index) => ({
          no: `${index + 1}.`,
          document: item.documentDescription,
          quantity: item.totalQty,
          uom: item.unit,
          notes: item.notes,
          key: generateUUID(),
          id: item.id ?? "",
        })),
      };
    },
    async handlePrint() {
      const { printReceipt } = useInvoiceAR();
      const { toObjectUrl } = useBlob();
      try {
        this.loading.print = true;
        const payload: GenerateReceipt = {
          billToAddress: this.state.billingAddress,
          branchName: this.state.branch,
          customerName: this.state.customer,
          details: this.state.documents.map(item => ({
            documentDescription: item.document,
            notes: item.notes,
            totalQty: item.quantity,
            unit: item.uom,
            id: item.id,
          })),
        };
        const params: RequestQueryParamsModel = {
          params:
            this.$store.state.authStore.authData.companyName ||
            "PT. SATRIA PIRANTI PERKASA",
        };
        const response = await printReceipt(payload, params);
        printJS(toObjectUrl(response));
        this.$emit("approved", true);
      } finally {
        this.loading.print = false;
      }
    },
    onSelectChange(values: string[]) {
      this.selectedRowKeys = values;
    },
    handleAddRow() {
      const row: InvoicePrintReceiptDocumentState = {
        document: "",
        id: "",
        key: generateUUID(),
        no: `${this.state.documents.length + 1}.`,
        quantity: 0,
        uom: "",
        notes: "",
      };
      this.state.documents.push(row);
    },
    handleDeleteRow() {
      const filtered = this.state.documents.filter(
        item => !this.selectedRowKeys.includes(item.key)
      );
      filtered.forEach((item, index) => {
        item.no = `${index + 1}`;
      });
      this.state.documents.length = 0;
      this.state.documents.push(...filtered);
    },
  },
});
