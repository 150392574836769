



















import { SearchBuilder } from "@/builder";
import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useTaxInvoice } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE } from "@/models/constant/global.constant";
import { TaxInvoiceCodeListDto } from "@/models/interface/tax-invoice-code";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectCustomerTax extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<TaxInvoiceCodeListDto>[] = [];
  loading = false;

  pagination = {
    page: 0,
    search: "",
  };

  allLoaded = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions(this.buildParams());
  }

  fetchOptions(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useTaxInvoice();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.allLoaded = res.currentPage + ONE === res.totalPages;
        const copy = [...this.options];
        this.options = [...copy, ...toOptions(res.data)];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { findAllBy } = useTaxInvoice();
    this.resetState();
    this.pagination.search = findAllBy({
      code: val,
    }).search;

    this.fetchOptions(this.buildParams());
  }

  findOption(value: string): Option<TaxInvoiceCodeListDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  buildParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const { page, search } = this.pagination;
    const findNotRevision = new SearchBuilder()
      .push(["revisionCode", "false"])
      .build();
    const queries = [findNotRevision];
    params.page = page;
    if (search) {
      queries.unshift(search);
    }
    params.search = queries.join(new SearchBuilder().AND);
    return params;
  }

  onScrollEnd(): void {
    if (this.loading || this.allLoaded) return;
    this.pagination.page += ONE;
    this.fetchOptions(this.buildParams());
  }

  resetState(): void {
    this.pagination.page = 0;
    this.options = [];
    this.allLoaded = false;
  }
}
