var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_posting"),
        visible: _vm.value,
        closable: "",
        "destroy-on-close": "",
        "data-testid": "trucking-ar-invoice-posting-modal"
      },
      on: { cancel: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-button",
                {
                  attrs: { "data-testid": "trucking-ar-invoice-posting-close" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: {
                    "data-testid": "trucking-ar-invoice-posting-approve",
                    type: "primary",
                    loading: _vm.loading.approve
                  },
                  on: { click: _vm.handleApprove }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: { model: _vm.formState, rules: _vm.schemaValidation }
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_invoice_received_date"),
                prop: "receivedDate"
              }
            },
            [
              _c("a-date-picker", {
                staticClass: "w-100",
                attrs: {
                  format: _vm.DEFAULT_DATE_FORMAT,
                  "disabled-date": _vm.disabledDate,
                  "allow-clear": false,
                  "data-testid": "trucking-ar-invoice-posting-received-date"
                },
                model: {
                  value: _vm.formState.receivedDate,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "receivedDate", $$v)
                  },
                  expression: "formState.receivedDate"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_pic_customer_name"), prop: "pic" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_pic_customer_name")
                  }),
                  "data-testid": "trucking-ar-invoice-posting-pic"
                },
                model: {
                  value: _vm.formState.pic,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "pic", $$v)
                  },
                  expression: "formState.pic"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }