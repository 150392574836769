var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        title: _vm.$t("lbl_invoice_summary_text", {
          text: _vm.state.invoiceNo
        }),
        "destroy-on-close": true,
        "force-render": false,
        closable: true,
        width: "75%"
      },
      on: { cancel: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-button",
                {
                  attrs: { "data-testid": "trucking-ar-invoice-summary-close" },
                  on: { click: _vm.handleClose }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [_c("a-spin")], 1)
        : [
            _c(
              "a-row",
              { attrs: { type: "flex", gutter: [16, 16] } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "a-descriptions",
                      { attrs: { column: 2, size: "small", bordered: "" } },
                      [
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_invoice_number"),
                              "data-testid":
                                "trucking-ar-invoice-summary-invoice-number"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.state.invoiceNo) + " ")]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_invoice_date"),
                              "data-testid": "trucking-ar-invoice-summary-date"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("date")(_vm.state.invoiceDate)) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_tax_type"),
                              "data-testid":
                                "trucking-ar-invoice-summary-invoice-tax-type"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.state.taxType) + " ")]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_term_of_payment"),
                              "data-testid": "trucking-ar-invoice-summary-top"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.state.top
                                    ? _vm.$tc(
                                        "common.top-text",
                                        +_vm.state.top,
                                        { text: _vm.state.top }
                                      )
                                    : "-"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_currency"),
                              "data-testid":
                                "trucking-ar-invoice-summary-currency"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.state.currency) + " ")]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_reference_number"),
                              "data-testid":
                                "trucking-ar-invoice-summary-reference"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.state.reference || "-") + " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_tax_invoice_number"),
                              "data-testid":
                                "trucking-ar-invoice-summary-tax-number"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.state.taxInvoiceNumber) + " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_customer"),
                              "data-testid":
                                "trucking-ar-invoice-summary-customer"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.state.customerName) + " ")]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_billing_address"),
                              span: 2,
                              "data-testid":
                                "trucking-ar-invoice-summary-address"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.state.customerBillToAddress) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_usage_type"),
                              "data-testid":
                                "trucking-ar-invoice-summary-usage-type"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.state.usageType || "-") + " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_description"),
                              "data-testid":
                                "trucking-ar-invoice-summary-description"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.state.description || "-") + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("a-col", { attrs: { span: 24 } }, [
                  _c(
                    "fieldset",
                    [
                      _c("legend", [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_product")) + " ")
                      ]),
                      _c("a-table", {
                        staticClass: "mt-2",
                        attrs: {
                          "data-source": _vm.state.invoiceArLinePrintDTOList,
                          columns: _vm.columns,
                          "data-testid": "trucking-ar-invoice-summary-table",
                          size: "small"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("a-col", { attrs: { span: 24, align: "end" } }, [
                  _c(
                    "table",
                    {
                      staticClass: "ml-auto",
                      attrs: { "aria-describedby": "invoice summary modals" }
                    },
                    [
                      _c("thead", [_c("th", { attrs: { colspan: "2" } })]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("lbl_subtotal")) + ":")
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "p-2",
                              attrs: {
                                "data-testid":
                                  "trucking-ar-invoice-summary-subtotal"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(_vm.state.subTotal)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("lbl_discount")) + ":")
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "p-2",
                              attrs: {
                                "data-testid":
                                  "trucking-ar-invoice-summary-discount"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(_vm.state.discount)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("lbl_base_amount")) + ":")
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "p-2",
                              attrs: {
                                "data-testid":
                                  "trucking-ar-invoice-summary-base-amount"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(_vm.state.taxBase)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(_vm.$t("lbl_vat")) + ":")]),
                          _c(
                            "td",
                            {
                              staticClass: "p-2",
                              attrs: {
                                "data-testid": "trucking-ar-invoice-summary-vat"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currency")(_vm.state.ppn)) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("lbl_grand_total")) + ":")
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "p-2",
                              attrs: {
                                "data-testid":
                                  "trucking-ar-invoice-summary-grand-total"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(_vm.state.grandTotal)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }