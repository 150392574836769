var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_print_receipt_document"),
        visible: _vm.value,
        closable: "",
        "destroy-on-close": "",
        width: "75%",
        "data-testid": "trucking-ar-invoice-print-receipt-modal"
      },
      on: { cancel: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "trucking-ar-invoice-print-receipt-close"
                      },
                      on: { click: _vm.handleClose }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid":
                          "trucking-ar-invoice-print-receipt-print",
                        type: "primary",
                        loading: _vm.loading.print
                      },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-skeleton",
        {
          attrs: {
            active: "",
            loading: _vm.loading.data,
            paragraph: { rows: 3 },
            title: false
          }
        },
        [
          _c(
            "a-descriptions",
            { attrs: { bordered: "", column: 1, size: "small" } },
            [
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_customer_name"),
                    "data-testid": "trucking-ar-invoice-print-receipt-customer"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.state.customer) + " ")]
              ),
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_billing_address"),
                    "data-testid":
                      "trucking-ar-invoice-print-receipt-billing-address"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.state.billingAddress) + " ")]
              ),
              _c(
                "a-descriptions-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_branch"),
                    "data-testid": "trucking-ar-invoice-print-receipt-branch"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.state.branch) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fieldset",
        { staticClass: "mt-3" },
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_details")) + " ")]),
          _c("a-table", {
            attrs: {
              "data-source": _vm.state.documents,
              columns: _vm.columns,
              pagination: {
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                }
              },
              "row-class-name": function(_record, index) {
                return index % 2 ? "bg-white" : "bg-gray-light"
              },
              size: "small",
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange
              },
              "data-testid": "trucking-ar-invoice-print-receipt-table"
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "a-space",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              type: "primary",
                              "data-testid":
                                "trucking-ar-invoice-print-receipt-add-row"
                            },
                            on: { click: _vm.handleAddRow }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                        ),
                        _c(
                          "a-button",
                          {
                            attrs: {
                              type: "danger",
                              disabled: _vm.selectedRowKeys.length === 0,
                              "data-testid":
                                "trucking-ar-invoice-print-receipt-delete-row"
                            },
                            on: { click: _vm.handleDeleteRow }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "document",
                fn: function(text, record) {
                  return [
                    _c("a-textarea", {
                      attrs: {
                        "allow-clear": "",
                        placeholder: _vm.$t("common.insert-text", {
                          text: _vm.$t("lbl_document")
                        }),
                        "data-testid":
                          "trucking-ar-invoice-print-receipt-document"
                      },
                      model: {
                        value: record.document,
                        callback: function($$v) {
                          _vm.$set(record, "document", $$v)
                        },
                        expression: "record.document"
                      }
                    })
                  ]
                }
              },
              {
                key: "quantity",
                fn: function(text, record) {
                  return [
                    _c("a-input-number", {
                      staticClass: "w-100",
                      attrs: {
                        placeholder: _vm.$t("common.insert-text", {
                          text: _vm.$t("lbl_quantity")
                        }),
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        min: 0,
                        precision: _vm.storeBaseDecimalPlace,
                        "data-testid":
                          "trucking-ar-invoice-print-receipt-quantity"
                      },
                      model: {
                        value: record.quantity,
                        callback: function($$v) {
                          _vm.$set(record, "quantity", $$v)
                        },
                        expression: "record.quantity"
                      }
                    })
                  ]
                }
              },
              {
                key: "uom",
                fn: function(text, record) {
                  return [
                    _c("a-input", {
                      attrs: {
                        "allow-clear": "",
                        placeholder: _vm.$t("common.insert-text", {
                          text: _vm.$t("lbl_uom")
                        }),
                        "data-testid": "trucking-ar-invoice-print-receipt-uom"
                      },
                      model: {
                        value: record.uom,
                        callback: function($$v) {
                          _vm.$set(record, "uom", $$v)
                        },
                        expression: "record.uom"
                      }
                    })
                  ]
                }
              },
              {
                key: "note",
                fn: function(text, record) {
                  return [
                    _c("a-textarea", {
                      attrs: {
                        "allow-clear": "",
                        placeholder: _vm.$t("common.insert-text", {
                          text: _vm.$t("lbl_note")
                        }),
                        "data-testid": "trucking-ar-invoice-print-receipt-notes"
                      },
                      model: {
                        value: record.notes,
                        callback: function($$v) {
                          _vm.$set(record, "notes", $$v)
                        },
                        expression: "record.notes"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }