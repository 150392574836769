







































































































































































import currencyFilter from "@/filters/currency.filter";
import { useInvoiceAR } from "@/hooks";
import { ResponseSummaryInvoiceAR } from "@/models/interface/invoice.interface";
import Vue from "vue";

export default Vue.extend({
  name: "InvoiceSummaryModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    invoiceId: String,
  },
  data() {
    return {
      loading: false,
      state: {
        customerBillToAddress: "",
        invoiceNo: "",
        invoiceDate: "",
        taxType: "",
        customerName: "",
        top: "",
        usageType: "",
        description: "",
        taxInvoiceNumber: "",
        poNo: "",
        reference: "",
        currency: "",
        subTotal: "",
        discount: "",
        taxBase: "",
        ppn: "",
        grandTotal: "",
        invoiceArLinePrintDTOList: [],
      } as ResponseSummaryInvoiceAR,
      columns: [
        {
          title: this.$t("lbl_unit"),
          dataIndex: "unit",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_price"),
          dataIndex: "price",
          align: "right",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_total"),
          dataIndex: "subtotal",
          align: "right",
          customRender: (text: number) => currencyFilter(text),
        },
      ],
    };
  },
  watch: {
    invoiceId: {
      immediate: true,
      handler(value: string) {
        if (value) {
          this.getSummary(value);
        }
      },
    },
  },
  methods: {
    handleClose() {
      this.$emit("input", false);
      this.$emit("close", false);
    },
    async getSummary(invoiceId: string) {
      const { findSummary } = useInvoiceAR();
      try {
        this.loading = true;
        const response = await findSummary(invoiceId);
        this.state = response;
      } finally {
        this.loading = false;
      }
    },
  },
});
