















































import ModalStateBuilder from "@/builder/ModalStateBuilder";
import AmortizationTransactionTypeModals from "@/components/Amortization/AmortizationTransactionTypeModals.vue";
import VNodes from "@/components/VNodes/VNodes.vue";
import { Option } from "@/models/class/option.class";
import { AmortizationTransactionTypeDto } from "@/models/interface/amortization";
import { amortizationService } from "@/services/amortization.service";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    VNodes,
    AmortizationTransactionTypeModals,
  },
})
export default class SelectAmortizationTransactionType extends Vue {
  @Prop({ required: false, default: undefined })
  value!: string | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  readonly hasCreate!: boolean;

  @Prop({ required: false, type: String, default: "default" })
  mode!: string;

  options: Option<string | null>[] = [];

  loading = false;
  loadingAdd = false;

  modalState = ModalStateBuilder.build({});

  created(): void {
    this.fetchOptions();
  }

  fetchOptions(): void {
    this.loading = true;
    amortizationService
      .getTransactionTypes()
      .then(data => {
        this.options = data.map(item => ({
          label: item.name,
          value: item.name,
          key: item.name,
          meta: item.description,
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e?: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  handleAdd(): void {
    this.modalState.open();
  }

  handleFinish({ payload }: { payload: AmortizationTransactionTypeDto }): void {
    this.options.push({
      label: payload.name,
      value: payload.name,
      key: payload.name,
      meta: payload.description,
    });
    this.$emit("input", payload.name);
  }
}
